import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";

const Teremberles = ({ data }) => (
  <Layout>
    <div className="container content-wrap">
      <div className="row my-3">
        <div className="col">
          <h1>Terembérlés</h1>
        </div>
      </div>

      <div className="row">
        <div className="col-lg">
          <GatsbyImage
            image={getImage(data.room1)}
            alt="Terembérlés"
            className="rounded img-fluid my-3"
          />
        </div>
        <div className="col-lg">
          <GatsbyImage
            image={getImage(data.room2)}
            alt="Terembérlés"
            className="rounded img-fluid my-3"
          />
        </div>
        <div className="col-lg">
          <GatsbyImage
            image={getImage(data.room3)}
            alt="Terembérlés"
            className="rounded img-fluid my-3"
          />
        </div>
      </div>

      <div className="row">
        <div className="col">
          <ul>
            <li>Megfelelő helyszínt keresel?</li>
            <li>
              Mit szólnál, ha ez a helyszín túlmutatna a „megfelelőn”, és egyedi
              bájával megadná az esemény alaphangulatát?
            </li>
            <li>
              Ha nem egy száraz, személytelen terembe érkeznének a vendégeid,
              hanem egy apró részletekig kigondolt, világos, barátságos térbe?
            </li>
          </ul>
        </div>
      </div>

      <div className="row my-3">
        <div className="col">
          <h2>Kinek ajánljuk?</h2>
          <p className="text-justify">
            Termeinket külön-külön és egyben is bérbe adjuk, alkalmi jelleggel
            vagy akár rendszeresen; kis létszámú találkozóktól az 15-25 fős
            összejövetelekig sokféle lehetőséget biztosítunk. Előzetes
            megbeszélés szerint ételek, italok külső forrásból is behozhatók
            hozzánk.
          </p>
          <p>Termeinket ajánljuk:</p>
          <ul>
            <li>
              családi eseményekre (szülinap, évforduló, esküvő, keresztelő,
              lánybúcsú, stb.)
            </li>
            <li>
              céges eseményekre, összejövetelekre, egyedi tematikájú csapatépítő
              mókákra
            </li>
            <li>workshopok, tanfolyamok, tréningek megtartására</li>
            <li>
              rendhagyó ötletek megvalósítására, például: bérelj magadnak
              éttermet!
            </li>
          </ul>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <h2>Termeink</h2>
        </div>
      </div>

      <div className="row">
        <div class="card-group">
          <div className="card">
            <GatsbyImage
              image={getImage(data.room_3)}
              alt="Étterem rész, „romantikus” terem"
              className="card-img-top img-fluid  mx-auto d-block"
            />
            <div className="card-body">
              <h4 className="card-title">Nagyterem</h4>
              <ul>
                <li>8-16 fő</li>
                <li>Bérelhető nyitvatartási időn kívüli.</li>
                <li>Családi-, cégesrendezvények, szülinapi alkalmak</li>
              </ul>
            </div>
          </div>
          <div className="card">
            <GatsbyImage
              image={getImage(data.room_2)}
              alt="Teljes étterem rész"
              className="card-img-top img-fluid  mx-auto d-block"
            />
            <div className="card-body">
              <h4 className="card-title">Teljes étterem rész</h4>
              <ul>
                <li>20-25 fő</li>
                <li>Bérelhető nyitvatartási időn kívüli.</li>
                <li>Családi-, cégesrendezvények, szülinapi alkalmak</li>
              </ul>
            </div>
          </div>

          <div className="card">
            <GatsbyImage
              image={getImage(data.room_1)}
              alt="Pincehelység"
              className="card-img-top img-fluid  mx-auto d-block"
            />
            <div className="card-body">
              <h4 className="card-title">Pincehelység</h4>
              <ul>
                <li>5-20 fő</li>
                <li>
                  Workshopokra, asztalokkal körbeülve max 16 fő, zsúrok,
                  gyerekprogramok, állófogadások esetén akár 20 fő.
                </li>
                <li>Bérelhető a nap bármely szakaszában.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row my-3">
        <div className="col">
          <h2>Mit tudunk nyújtani még?</h2>
          <p>Az alap terembérlésen kívül az alábbiakban is tudunk segíteni:</p>
          <ul>
            <li>„mindenmentes” torta</li>
            <li>sós és édes aprósüti</li>
            <li>házi készítésű keksz</li>
            <li>
              hideg és meleg vegetáriánus vagy vegán étkezés (pl. sós és édes
              piték, zöldségkrémek, mártogatósok, sajtválogatás, házi pékáru,
              stb.)
            </li>
            <li>tálaló és felszolgáló személyzet.</li>
          </ul>
        </div>
      </div>

      <div className="row my-3">
        <div className="col">
          <h2>Vedd fel velünk a kapcsolatot!</h2>
          <p>
            A stílusos környezet és az igényes kapcsolódó szolgáltatások
            adottak, csak rajtad áll, mivel töltöd meg a tereket! További
            információért, szabad időpontokért kérjük vedd fel velünk e-mailben
            a kapcsolatot:{" "}
            <a
              className="linkDark"
              href="mailto:info@szelencecafe.hu?Subject=Terembérlés"
              target="_top"
            >
              info@szelencecafe.hu
            </a>
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export const pageQuery = graphql`
  query {
    room1: file(relativePath: { eq: "room/teremberles1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    room2: file(relativePath: { eq: "room/teremberles2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    room3: file(relativePath: { eq: "room/teremberles3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    room_1: file(relativePath: { eq: "room/pince2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    room_2: file(relativePath: { eq: "room/teljes_etterem.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    room_3: file(relativePath: { eq: "room/romantikus_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    room_4: file(relativePath: { eq: "room/gyerek.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export const Head = () => <title>Szelence Café | Terembérlés</title>;

export default Teremberles;
